import styled from '@emotion/styled'
import {css} from '@emotion/react'

const active = (props) => props.disabled ?
    css`` :
    css`
      bottom: -4px;
      box-shadow: 0px 0px 0px 0px rgba(199,134,86,1);
    `

export const ButtonWrap = styled.div`
  width: 132px;
  height: 42px;
  border-radius: 30px;
  background-color: ${props => props.disabled ? '#EAE7E5FF' : '#ffc15c'};
  position: relative;
  box-shadow: ${props => props.disabled ? '0px 4px 0px 0px #8F8C8CFF' : '0px 4px 0px 0px rgba(199,134,86,1)'};
  display: flex;
  justify-content: center;
  align-items: center;
  
  :active {
    ${active};
  }

  :hover{
    cursor: pointer;
  }
  
  .title {
    text-align: center;
    font-family: 'Open Sans', sans-serif;
    font-size: 16px;
    font-weight: 900;
    font-style: normal;
    color: ${props => props.disabled ? '#000000' : '#5a105c'};
    height: 42px;
    line-height: 42px;
  }
  
`