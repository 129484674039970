import { createContext, useEffect, useState, lazy, useContext } from "react";
import yaml from "js-yaml";
import Loading from "../routes/loading/loadingPage";

export const gameContext = createContext({});


export const GameContextProvider = (props) => {
  const [games, setGames] = useState({});
  const [smallTilesList, setSmallTilesList] = useState([]);
  const [mediumTilesList, setMediumTilesList] = useState([]);
  const [bigTilesList, setBigTilesList] = useState([]);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true);
  const titles = [];
  const ErrorPage = lazy(() => import("../routes/error/errorPage"));


  useEffect(() => {
    fetch(
      process.env.REACT_APP_STORAGE_URL +
        process.env.REACT_APP_CONFIG_BUCKET +
        "/config-telegram.yml",
      {}
    )
      .then((res) => res.blob())
      .then((blob) => blob.text())
      .then((s) => {
        try {
          let config = yaml.load(s);
          setGames(config.games);
          setSmallTilesList(config.small_tiles);
          setMediumTilesList(config.medium_tiles);
          setBigTilesList(config.big_tiles);
          setLoading(false);
        } catch (e) {
          console.log(e);
          setError(true);
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setError(true);
        setLoading(false);
      });
  } , []);
  titles.push(smallTilesList, mediumTilesList, bigTilesList);

  if (error) {
    return <ErrorPage />;
  }

  if (loading) {
    return <Loading />;
  }

  return(
    <gameContext.Provider value={{games,titles}}>
        {props.children}
    </gameContext.Provider>
  )
};

export const useGamesData = () => {
    const context = useContext(gameContext);
  
    return { ...context };
  };
