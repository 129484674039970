import styled from "@emotion/styled";
import TopWave from "../../assets/views/TopWave.svg";

export const ErrorWrap = styled.div`
  width: 100%;
  min-height: 100vh;
  position: relative;
  background: transparent;

  grid-column-start: 1;
  grid-row-start: 1;

  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 40px 40px 1fr;
  row-gap: 20px;

  .button {
    grid-column-start: 1;
    grid-row-start: 3;
    justify-self: center;
  }

  .message {
    grid-column-start: 1;
    grid-row-start: 2;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    font-family: "Open Sans", sans-serif;
    font-size: 24px;
    font-weight: 500;
    font-style: normal;
    color: rgba(255, 255, 255, 1);
  }

  .curve {
    z-index: -2;
    width: 100%;
    min-height: 100%;
    background-image: url(${TopWave});
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: cover; /* Resize the background image to cover the entire container */

    grid-column-start: 1;
    grid-row-start: 1;
    grid-row-end: span 4;
  }
`;
