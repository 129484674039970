
const validate = (object, schema) => Object
  .entries(schema)
  .map(([key, validate]) => [
    key,
    !validate.required || (key in object),
    validate(object[key])
  ])
  .filter(([_, ...tests]) => !tests.every(Boolean))
  .map(([key, invalid]) => new Error(`${key} is ${invalid ? 'invalid' : 'required'}.`));


export default validate;