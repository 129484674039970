import React from "react";
import ReactDOM from "react-dom/client";
import { css, Global } from "@emotion/react";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import ErrorBoundary from "./tools/boundary";
import { QueryClient, QueryClientProvider } from "react-query";

const GlobalStyles = css`
  html {
    width: 100%;
    height: 100%;
  }

  body {
    margin: 0;
    font-family: "Roboto";
    width: 100%;
    min-height: 100vh;
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: 1fr;

    //@font-face {
    //  font-family: "Yekan Bakh Heavy";
    //  src: url("//db.onlinewebfonts.com/t/3231c6a7b3ba5a66dd21bfc6b461165e.eot");
    //  src: url("//db.onlinewebfonts.com/t/3231c6a7b3ba5a66dd21bfc6b461165e.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/3231c6a7b3ba5a66dd21bfc6b461165e.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/3231c6a7b3ba5a66dd21bfc6b461165e.woff") format("woff"), url("//db.onlinewebfonts.com/t/3231c6a7b3ba5a66dd21bfc6b461165e.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/3231c6a7b3ba5a66dd21bfc6b461165e.svg#Yekan Bakh Heavy") format("svg");
    //}

    /* @font-face {
      font-family: 'Google Sans';
      font-style: normal;
      src: url(https://fonts.gstatic.com/s/googlesans/v58/4Ua_rENHsxJlGDuGo1OIlJfC6l_24rlCK1Yo_Iqcsih3SAyH6cAwhX9RFD48TE63OOYKtrwEIJl1pynAFyo4R4r0Mw.woff2) format('woff2');
      unicode-range: U+0308, U+0530-058F, U+2010, U+2024, U+25CC, U+FB13-FB17;
    } */
  }

  #root {
    width: 100%;
    min-height: 100%;
    grid-column-start: 1;
    grid-row-start: 1;
    justify-self: center;
    align-self: center;

    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: 1fr;
  }
`;

// Create a client
const queryClient = new QueryClient();

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <QueryClientProvider client={queryClient}>
        <Global styles={GlobalStyles} />
        <ErrorBoundary>
          <App />
        </ErrorBoundary>
      </QueryClientProvider>
    </BrowserRouter>
  </React.StrictMode>
);
