import {Session} from "@heroiclabs/nakama-js";


export const setSession = (session) => {

    window.localStorage.setItem("bckndToken", session.token)
    window.localStorage.setItem("bckndRefreshToken", session.refresh_token)

}

export const getSession = async (client) => {

    let authtoken = window.localStorage.getItem("bckndToken")
    let refreshtoken = window.localStorage.getItem("bckndRefreshToken")

    let session = Session.restore(authtoken, refreshtoken);

    // Check whether a session is close to expiry.

    if (session.isexpired(Date.now() / 1000)) {
        
            session = await client.sessionRefresh(session);
            return session
    }

    return session
}