import PropTypes from 'prop-types';

import Spinner from '../../../components/loading/spinner/spinner'
import {ButtonWrap} from './buttonStyles'

function Button(props){

    const contentProvider = () => {
        if (props.loading === true){
            return <Spinner size={20} color={'#5a105c'} />
        }

        return <div className="title">{props.title}</div>

    }


    return(
        <ButtonWrap
            disabled={props.disabled ? props.disabled : false}
            className={props.className}
            onClick={props.disabled ? null : props.onClick}
        >
            {contentProvider()}
        </ButtonWrap>
    )
}

Button.propTypes = {
    className: PropTypes.string,
    title: PropTypes.string,
    onClick: PropTypes.func,
    loading: PropTypes.bool,
    disabled: PropTypes.bool
}

export default Button;