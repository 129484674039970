import {useNavigate, useLocation} from 'react-router-dom'
import {ErrorWrap} from "./errorPageStyles";
import Button from '../../components/buttons/button/button'

function ErrorPage(props){

    const navigate = useNavigate()
    const { search } = useLocation();


    return (
        <ErrorWrap>
            <div className="message">Oops! Something went wrong...</div>
            <Button
                title="Start fresh!"
                onClick={() => {
                    navigate(`/${search}`)
                    window.location.reload()
                }}
                className="button"
            />
            <div className="curve"/>
        </ErrorWrap>
    )
}

export default ErrorPage