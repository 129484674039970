import {SpinnerWrap} from './spinnerStyles'
import CircularProgress from '@mui/material/CircularProgress';
import PropTypes from 'prop-types'

function Spinner(props){

    return (
        <SpinnerWrap>
            <CircularProgress
                size={props.size ? props.size : 40}
                sx={{color: props.color !== undefined ? props.color : '#ffffff'}}
            />
        </SpinnerWrap>
    )
}

Spinner.propTypes = {
    color: PropTypes.string,
    size: PropTypes.number
}

export default Spinner;