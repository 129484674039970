import React, { lazy, Suspense } from "react";
import Loading from "./routes/loading/loadingPage";
import { Route, Routes } from "react-router-dom";
import { GameContextProvider } from "./tools/gameContext";
import { UserContextProvider } from "./tools/userContext";

const GameWrapper = lazy(() => import("./routes/gameWrapper/GameWrapper"));
const LeaderBoardPage = lazy(() =>
  import("./routes/leaderboard/leaderBoardPage")
);

function App() {
  return (
    <React.Fragment>
      <GameContextProvider>
        <UserContextProvider>
          <Suspense fallback={<Loading />}>
            <Routes>
              {/* <Route
                path="*"
                element={
                  <main style={{ padding: "1rem" }}>
                    <p>There's nothing here!</p>
                  </main>
                }
              /> */}
              <Route path="/starting_page" element={<GameWrapper />} />
              <Route path="/board/:game_id" element={<LeaderBoardPage />} />
            </Routes>
          </Suspense>
        </UserContextProvider>
      </GameContextProvider>
    </React.Fragment>
  );
}

export default App;
