import { createContext, useEffect, useState, lazy, useContext } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { newClient } from "../tools/backendContext";
import { setSession } from "../tools/localStorage";
import { jwtDecode } from "jwt-decode";
import validate from "../tools/validate";
import { MarkAsUnread } from "@mui/icons-material";
const ErrorPage = lazy(() => import("../routes/error/errorPage"));

export const userSchema = {
  username: (value) => true,
  token: (value) => true,
};

userSchema.username.required = true;
userSchema.token.required = true;

export const userContext = createContext({});

export const UserContextProvider = (props) => {

  const location = useLocation();
  const queryParam = new URLSearchParams(location.search);
  const token = queryParam.get("token");
  const [user, setUser] = useState({});
  const [error, setError] = useState(false);
  const [onTournament, setOnTournament] = useState(false);

  useEffect(() => {
    if (token) {
      const backendInitializr = async (user) => {
        var client = newClient();
        try {
          const session = await client.authenticateCustom(
            user.token,
            true,
            user.username
          );
          setSession(session);
        } catch (err) {
          console.log(err);
          setError(true);
        }
      };

      let u = {
        token: token,
        username: "gamer",
      };

      try {
        const decoded = jwtDecode(u.token);
        if (typeof decoded.username == "undefined") {
          console.log("username not provided as a token claim");
          setError(true);
          return;
        }
        u.username = decoded.username;
      } catch (e) {
        console.log(e);
        setError(true);
        return;
      }

      const errors = validate(u, userSchema);

      if (errors.length > 0) {
        console.log(errors);
        setError(true);
        return;
      } else {
        setUser(u);
      }

      backendInitializr(u);
    }
  }, [token]);

  if (error) {
    return <ErrorPage />;
  }

  return (
    <userContext.Provider value={{ user, onTournament, setOnTournament }}>
      {props.children}
    </userContext.Provider>
  );
};

export const useUserData = () => {
  const context = useContext(userContext);

  return { ...context };
};
